const theme = {
  colors: {
    primary: '#212121',
    secondary: '#ffffff',
    input: '#757575',
    white: '#ffffff',
    black: '#000000',
  },
}

export default theme
